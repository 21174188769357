.App {
  height: 92vh;
  padding: 3rem;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,url(./bubble.jpg); */
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)) , url(./light2.jpg);
  background-size: cover;
  background-position: center;
  /* background: linear-gradient(220deg, rgba(28, 52, 31, 1), rgba(0, 0, 0, 1), rgba(28, 52, 31, 1)); */
  /* background-position: -15rem; */
  margin: 0 auto;
}

* {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
}

.Main-Container-Wrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: .5rem;
  grid-row-gap: 0px;
  height: 100%;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.115); */
  display: flex;
  border-radius: 2rem;
  color: white;
  transition: 1s;
}

.lightModeApp {
  background: rgb(255, 255, 255);
  background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)) , url(./light2.jpg);
  background-size: cover;
  background-position: center;
}

.lightModeMain {
  color: rgb(0, 0, 0);
  /* background: rgba(255, 255, 255, 0.35); */
}

.lightModeBorder {
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.502);
  margin: 0 1rem;
}

.modeSwitch {
  margin: 0 0 .5rem 0;
  padding: .3rem .55rem;
  border-radius: 100%;
  border: 1px solid white;
}

.modeSwitchLight {
  background: linear-gradient(100deg, rgba(100,100,100,.8), rgba(0,0,0,.8));
}

.snow-btn {
  position: fixed;
  bottom: 5px;
  right: 5px;
  font-size: 1rem;
  margin: 0 auto;
}


.LightMode-wrapper {
  display: flex;
  width: 90%;
  align-items: center;
}

.lightModeNav {
  background: rgba(141, 186, 222, 0.4);
  border-radius: 2rem;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

nav {
  grid-area: 1 / 1 / 9 / 3; 
  display: grid;
  grid-template-rows: 1fr .05fr 3fr;
  background: rgba(46, 46, 60, .5);
  border-radius: 2rem;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

nav::-webkit-scrollbar {
  display: none;
}

.Routes {
  grid-area: 1 / 3 / 9 / 9; 
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.Routes::-webkit-scrollbar {
  display: none;
}

.Border-line {
  border-bottom: .3px solid rgba(255, 255, 255, 0.254);
  margin: 0 1rem;
}


.Avatar-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  line-height: 3rem;
}

.Avatar {
  background: url(./avatar.png), linear-gradient(90deg, rgba(0, 0, 0, 0.855), rgba(255, 255, 255, 0.499), rgba(0, 0, 0, 0.855));
  min-height: 10rem;
  min-width: 10rem;
  background-size: 100%;
  border-radius: 50%;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.501);
}

.border {
  border: 1px solid rgb(13, 255, 0);
}

.Routes-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}


.HomeHref {
  color: #FFF4CD;
}

.lightHome {
  color: #785e01;
  
}

.AboutHref {
  color: #F9D7D7;
}

.lightAbout {
  color: #6f2c2c;
}

.PortfolioHref {
  color: #D7FFF3;
}

.lightPortfolio {
  color: #017854;
}

.ExperiencesHref {
  color: #E6DAFF;
}

.lightExperiences {
  color: #4d2e90;
 }
 
.ContactHref {
 color: #FF9595;
}

.lightContact {
  color: #a43333;
}

a {
  text-decoration: none;
  font-size: 1.5rem;
  text-align: center;
  padding: .5rem 4rem;
  border-radius: 2rem;
  border: .5px solid rgba(255, 255, 255, 0);
}

.currentPage {
  background: rgba(255, 255, 255, 0.6);
}

.nav-tags:hover {
  border: .5px solid white;
}

.name {
  font-size: 2rem;
  margin-top: 1rem;
}

.role {
  font-size: 1.2rem;
  color: rgb(200, 200, 200);
}

.lightModeRole {
  color: rgb(50, 50, 50);
}

.Home-Info-Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.Home-Info {
  width: 60%;
}

.HomeName {
  font-size: 2.5rem;
}

.paragraph {
  font-size: 2rem;
}

.ClickAbout {
  color: #F9D7D7;
  display: inline-block;
  font-style: italic;
  animation: pulse 5s infinite;
  -webkit-animation: pulse 5s infinite;
  transition: 1s;
}

.lightClickAbout {
  color: #672a2a;
}

.ClickAbout:hover {
  text-decoration: underline;
}

@keyframes pulse {
  0% {
    
  }
  50% {
    transform: scale(1.3);
  }
  100% {

  }
}

.About-Info-Wrapper {
  height: 100%;
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: auto;
}

.About-Info-Wrapper::-webkit-scrollbar {
  display: none;
} 

.ProfilePic-Wrapper {
  grid-area: 1 / 1 / 3 / 2; 
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.ProfilePic {
  background: url(./mypic1.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 38rem;
  max-width: 35rem;
  display: flex;
}


.AboutMe {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Skills {
  grid-area: 2 / 2 / 3 / 3; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.About-titles {
  font-size: 2rem;
  display:inline-block;
  margin: 1rem;
}

.Skills-Wrapper p {
  display: inline-block;
  margin: .5rem;
  background: rgba(200,200,200,.3);
  padding: .8rem;
  border-radius: 2rem;
  box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.355);
}

.lightSkills p {
  display: inline-block;
  margin: .5rem;
  background: rgba(255, 255, 255, 0.682);
  padding: .8rem;
  border-radius: 2rem;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.355);
}

.AboutMe-Wrapper {
  margin: 0rem 1rem 0rem 2rem;
  line-height: 2rem;
}

.AboutMe-Wrapper p {
  font-size: 1.2rem;
  text-align: left;
  margin: 0 auto;
}

.Indent {
  margin-left: 2rem;
}

.message form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 2rem;
  padding: 2rem;
  border-radius: 2rem;
  width: 60%;
  max-width: 27rem;
  height: 60%;
  font-weight: bold;
  font-size: 1.3rem;
}

.darkForm {
  background: rgba(255, 255, 255, 0.154);
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.355);
}

.lightForm {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.355);
}

.message {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.popup {
  position: absolute;
  top: 19%;
  background: rgba(30, 30, 46, 9);
  border-radius: 2rem;
  width: 45%;
  height: 55%;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade 2s linear forwards;
  padding: 2rem;
  transition: 1s;
}

.lightPopup {
  color: black;
  background: rgba(141, 186, 222, 0.9);
}

@keyframes fade {
  0% {
    opacity: 0%;
    border-radius: 100%;
  }
}

.popup h4 {
  font-size: 2rem;
}

.popup button {
  font-size: 1.2rem;
  padding: .8rem;
}

input, textarea {
  border-radius: .7rem;
  padding: .25rem;
  border: none;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

textarea {
  resize: none;
}

button {
  margin: 1rem 8rem 0 8rem;
  cursor: pointer;
  border-radius: .7rem;
  border: none;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 255, 255, 0.5);
  font-weight: bold;
  color: white;
  padding: .3rem;
  font-size: 1.2rem;
}

.lightBtn {
  background: rgba(38, 136, 136, 0.5);
}

button:hover {
  opacity: 70%;
}

.contact-icons {
  display: flex;
  height: 8vh;
  align-items: center;
  justify-content: space-around;
  background: rgba(203, 206, 234, 0.158);
  border-radius: 2rem;
  width: 50%;
  max-width: 32rem;
  transition: 1s;
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.355);
}

.lightContact-icons {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.355);
}

.github {
  background: rgb(255, 255, 255) url(./github.svg);
}

.linkedin {
  background: rgb(255, 255, 255) url(./linkedin.svg);
}

.email {
  background: url(./email.svg);
}

.github, .linkedin{
  background-size: cover;
  border-radius: 1rem;
  height: 3rem;
  width: 3rem;
}

.email {
  height: 3rem;
  width: 3rem;
  background-color: rgba(235, 199, 247, 0.513);
  border-radius: 1rem;
}

.contact-icons a {
  padding: .3rem;
}

.contact-icons a:hover {
  opacity:50%;
}

.admin-btn {
  display: flex;
  flex-direction: column;
}

.admin-btn a:hover {
  color: rgb(255, 230, 0);
}

.second-main {
  width: 100%;
}

main h1 {
  display: flex;
  justify-content: center;
}

.message-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem;
  padding: 2rem;
  height: 70%;
  overflow: auto;
}

.message-info-wrapper {
  background: rgba(200, 200, 200, .2);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 11rem;
  line-height: 2rem;
  padding: .5rem 1rem 1.5rem 1rem;
  overflow: auto;
}

.light-message-wrapper {
  background: rgb(255, 255, 255, .9);
}

.message-info-wrapper::-webkit-scrollbar, .message-wrapper::-webkit-scrollbar {
  display: none;
}

.date {
  display: flex;
  width: 100%;
  text-align: left;
  font-style: italic;
  color: rgb(255, 98, 0);
}

.delete-btn {
  background: none;
  box-shadow: none;
  color: red;
  margin: 0 auto;
}

.mark-read-btn {
  background: none;
  box-shadow: none;
  margin: 0 auto;
  opacity: 30%;
}

.one-line {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.new-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(219, 49, 49);
}

.admin-text:visited {
  color: rgb(255, 0, 212);
}

.logout-text:visited {
  color: white;
}

.myMessages {
  text-align: center;
  color: yellow;
}

.form-container-wrapper {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-evenly;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center
}

.form-container form {
  display: flex;
  flex-direction: column;
  color: white;
  line-height: 2rem;
  background: rgba(255, 255, 255, 0.154);
  border-radius: 2rem;
  padding: 5rem 3rem;
}

.Experience-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 2rem;
  margin: 2rem;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.355);
}

.lightExperience-wrapper {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.200), rgba(255, 255, 255, 0.500) );
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.355);
  
}

.Experience-wrapper::-webkit-scrollbar {
  display: none;
}

.Yilan-wrapper, .Volunteer-wrapper, .OTN-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5rem 3rem;
}

.Yilan-img {
  background-image: url(./Yilan.jpg);
}

.Yilan-info, .Volunteer-info, .OTN-info {
  width: 40%;
  font-size: 1.2rem;
}

.Volunteer-img {
  background: url(./Volunteer.jpg);
}

.OTN-img {
  background: url(./OTN.jpg);
}

.Yilan-img, .Volunteer-img, .OTN-img {
  background-size: cover;
  background-position: center;
  height: 15rem;
  width: 22rem;
  max-width: 35rem;
  border-radius: 2rem;
}

.Projects-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.timeSup-wrapper, .foodscovery-wrapper, .blackjack-wrapper, .rokitshop-wrapper {
  background: rgba(255, 255, 255, 0.1);
  margin: 3rem 6rem;
  padding: 0 2rem 3rem 2rem;
  border-radius: 2rem;
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.355);
}

.light-project {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.355);
}

.timeSup-wrapper::-webkit-scrollbar, .foodscovery-wrapper::-webkit-scrollbar, .blackjack-wrapper::-webkit-scrollbar, .rokitshop-wrapper::-webkit-scrollbar  {
  display: none;
}

.slide-container {
  width: 900px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-container {
  width: 2100px;
  height: 450px;
  position: relative;
  transition: left 2s;
  -webkit-transition: left 2s;
  -moz-transition: left 2s;
  -o-transition: left 2s;
  left: 130px;
}

.slider-image {
  float: left;
  padding: 0px;
  border-radius: .5rem;
  opacity: 20%;
  transform: scale(.7);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.12);
  transition: 2s;
  filter: blur(3px);
}

.selected-image {
  opacity: 100%;
  transform: scale(1);
  filter: none;
}

.button-container {
  top: 15px;
  position: relative;
}

.slider-button {
  display: inline-block;
  height: 15px;
  width: 15px;
  padding: 0px;
  margin: 5px;
  border-radius: 100%;
  background-color: rgb(255, 255, 255);
  opacity: 50%;
}

.project-btn {
  margin: 1rem;
  padding: .5rem .7rem;
  border-radius: 1rem;
  transition: none;
  background: linear-gradient(30deg, rgba(130,193,249, .5), rgba(255, 255, 255, .5));
}

.project-btn:hover {
  opacity: 100%;
  box-shadow: 0px 0px 10px 5px #2992bd;
}

.selected-project {
  box-shadow: 0px 0px 10px 5px #2992bd;
}

#slider-image-1:target ~ .image-container {
  left: 130px;
}

#slider-image-2:target ~ .image-container {
  left: -520px;
}

#slider-image-3:target ~ .image-container {
  left: -1170px;
}

#slider-image-4:target ~ .image-container {
  left: -995px;
}


.selected {
  opacity: 100%;
}

.project-links {
  display: flex;
  justify-content: space-evenly;
}

.project-links a {
  text-decoration: none;
  color: rgb(255, 0, 132);
  border-radius: 0;
  margin-bottom: 1rem;
  padding: 0;
  display: inline-block;
  padding-bottom: 2px;
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition:
    background-size 0.3s,
    background-position 0s 0.3s; /*change after the size immediately*/
}

.dark-a {
  background-image: linear-gradient(rgb(254, 254, 254) 0 0);
}

.light-a {
  background-image: linear-gradient(rgb(55, 55, 55) 0 0);
}

.project-links a:visited {
  color: rgb(255, 0, 132);
}

.project-links a:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 1px;
}

.resume-wrapper a {
  color: black;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.509);
  padding: 0.1rem 1rem;
  border-radius: .5rem;
}

.resume-wrapper a:hover {
  opacity: 50%;
}

.project-btn-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 1500px) {
  .slider-image {
    height: 350px;
    width: 450px;
  }
  .mobile-image {
    height: 350px;
    width: 140px;
  }
  .image-container {
    height: 350px;
    width: 1400px;
    left: 176px;
  }
  .slide-container {
    height: 400px;
    width: 800px
  }
  #slider-image-1:target ~ .image-container {
    left: 176px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -275px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -725px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -570px;
  }
}

@media only screen and (max-width: 1180px), screen and (max-height: 650px) {
  .About-Info-Wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .About-Info-Wrapper::-webkit-scrollbar {
    display: none;
  }
  nav {
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
    height: 90vh;
    width: 100vw;
    color: white;
    border-radius: 0 0 2rem 2rem;
    background: rgba(46, 46, 60, .9);
    z-index: 2;
  }
  .showNav {
    visibility: visible;
    animation: expand 1s;
  }

  .hideNav {
    animation: collapse 1s;
    height: 0;
  }

  @keyframes collapse {
    0% {
      height: 90vh;
    }
    100% {
      height: 0vh;
    }
  }

  @keyframes expand {
    0% {
      height: 0vh;
    }
    100% {
      height: 90vh;
    }
  }

  .App {
    padding: 2rem;
    height: 92vh;
  }
  .ProfilePic-Wrapper {
    height: 44vh;
    align-items: center;
  }
  .ProfilePic {
    width: 60vw;
    height: 25rem;
  }
  .contact-icons {
    border-radius: 2rem;
    width: 50%;
  }
  .message form {
    width: 60%;
    height: 65%;
  }
  .message-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    overflow: auto;
  }
  .popup {
    top: 16.2%;
    width: 60%;
    height: 65%;
    padding: 1rem;
  }
  .Experience-wrapper {
    justify-content: flex-start;
  }
  .Yilan-wrapper, .Volunteer-wrapper, .OTN-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  .Volunteer-wrapper {
    flex-direction: column-reverse;
  }
  .Yilan-info, .Volunteer-info, .OTN-info {
    width: 80%;
    font-size: 1.1rem;
  }
  .Yilan-img, .Volunteer-img, .OTN-img {
    background-size: cover;
    background-position: center;
    height: 28rem;
    width: 80%;
    border-radius: 2rem;
  }
  .lightModeNav {
    background: rgba(141, 186, 222, 0.9);
    border-radius: 0 0 2rem 2rem;
  }
  .role {
    color: white;
  }
  .LightMode-wrapper {
    justify-content: flex-end;
    width: 96%;
  }
  .project-btn {
    margin: .8rem;
  }
  .small-screen {
    background: url(./menuicon.png);
    background-size: 100%;
    height: 3.5rem;
    width: 3.5rem;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 3;
    cursor: pointer;
  }
  .close-menu {
    background: url(./closemenu.png);
    background-size: 100%;
    height: 3.5rem;
    width: 3.5rem;
    color: red;
  }
}


@media only screen and (max-height: 700px) {
  .github, .linkedin, .email {
    height: 2.5rem;
    width: 2.5rem;
  }
  nav::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-height: 720px) {
  .Avatar {
    min-height: 6rem;
    min-width: 6rem;
  }
  .name {
    font-size: 1.5rem;
    margin-top: .5rem;
  }
}


@media only screen and (max-height: 1044px) {
  .Experience-wrapper {
    justify-content: flex-start;
  }
}

@media only screen and (max-height: 950px) {
  .slider-image {
    height: 350px;
    width: 450px;
  }
  .mobile-image {
    height: 350px;
    width: 140px;
  }
  .image-container {
    height: 350px;
    width: 1400px;
    left: 176px;
  }
  .slide-container {
    height: 400px;
    width: 800px
  }
  #slider-image-1:target ~ .image-container {
    left: 176px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -275px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -725px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -570px;
  }
}

@media only screen and (max-width: 1450px) {
  .slider-image {
    height: 300px;
    width: 450px;
  }
  .mobile-image {
    height: 300px;
    width: 140px;
  }
  .image-container {
    height: 300px;
    width: 1400px;
    left: 106px;
  }
  .slide-container {
    height: 343px;
    width: 650px;
  }
  #slider-image-1:target ~ .image-container {
    left: 106px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -348px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -800px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -645px;
  }
}

@media only screen and (max-width: 1250px) {
  .slider-image {
    height: 300px;
    width: 400px;
  }
  .mobile-image {
    height: 300px;
    width: 140px;
  }
  .image-container {
    height: 300px;
    width: 1400px;
    left: 101px;
  }
  .slide-container {
    height: 343px;
    width: 600px;
  }
  #slider-image-1:target ~ .image-container {
    left: 107px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -298px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -695px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -570px;
  }
}

@media only screen and (max-width: 900px) {
  .timeSup-wrapper, .foodscovery-wrapper, .blackjack-wrapper, .rokitshop-wrapper {
    margin: 3rem 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .timeSup-wrapper, .foodscovery-wrapper, .blackjack-wrapper, .rokitshop-wrapper {
    margin: 3rem 1rem;
  }
}

@media only screen and (max-height: 780px) {
  .slider-image {
    height: 200px;
    width: 300px;
  }
  .mobile-image {
    height: 200px;
    width: 100px;
  }
  .image-container {
    height: 200px;
    width: 1400px;
    left: 146px;
  }
  .slide-container {
    height: 244px;
    width: 600px;
  }
  #slider-image-1:target ~ .image-container {
    left: 146px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -152px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -451px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -350px;
  }
   .timeSup-wrapper, .foodscovery-wrapper, .blackjack-wrapper, .rokitshop-wrapper {
    margin: 2rem 5rem 3rem 5rem;
  }
}

@media only screen and (max-width: 728px) {
  .slider-image {
    height: 300px;
    width: 400px;
  }
  .mobile-image {
    height: 300px;
    width: 140px;
  }
  .image-container {
    height: 300px;
    width: 1200px;
    left: 90px;
  }
  .slide-container {
    height: 343px;
    width: 570px;
  }
  #slider-image-1:target ~ .image-container {
    left: 90px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -315px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -715px;
  }
  #slider-image-4:target ~ .image-container {
    left: -585px;
  }
}

@media only screen and (max-width: 700px) {
  nav {
    height: 85vh;
    overflow: auto;
    width: 100vw;
    border-radius: 0 0 2rem 2rem;
  }
  .showNav {
    visibility: visible;
    animation: expand 1s;
    -webkit-animation: expand 1s;
  }

  .hideNav {
    animation: 1s collapse;
    -webkit-animation: 1s collapse;
    height: 0;
  }

  @keyframes expand {
    0% {
      height: 0vh;
    }
    100% {
      height: 85vh;
    }
  }

  @keyframes collapse {
    0% {
      height: 85vh;
    }
    100% {
      height: 0vh;
    }
  }

  .small-screen {
    width: 2.5rem;
    height: 2.5rem;
    top: .5rem;
    left: .5rem;
  }
  .close-menu {
    height: 2.5rem;
    width: 2.5rem;
  }
  
  .App {
    padding: 1.5rem;
    height: 92vh;
  }
  .Home-Info-Wrapper {
    height: 90vh;
  }
  .Home-Info {
    width: 95%;
  }
  .ClickAbout {
    padding: 0px 2rem;
  }
  .About-Info-Wrapper {
    margin: 0 auto;
  }
  a {
    padding: .3rem 4rem;
  }
  .message form {
    width: 80%;
    max-width: 25rem;
    height: 65%;
  }
  .github, .linkedin, .email {
    height: 2.5rem;
    width: 2.5rem;
  }
  .message-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
    padding: 2rem;
    overflow: auto;
  }
  .popup {
    top: 16.5%;
    width: 80%;
    height: 65%;
    padding: 1rem;
  }
  .ProfilePic {
    margin-top: 1rem;
    height: 20rem;
    width: 18rem;
  }
  button {
    margin: 1rem 4rem 0 4rem;
  }
  .Yilan-info, .Volunteer-info, .OTN-info {
    width: 100%;
    font-size: 1.1rem;
  }
  .Yilan-img, .Volunteer-img, .OTN-img {
    background-size: cover;
    background-position: center;
    height: 15rem;
    border-radius: 2rem;
  }
  .Experience-wrapper {
    padding: 0;
    margin: 0;
  }
  .Yilan-wrapper, .Volunteer-wrapper, .OTN-wrapper {
    padding: 1rem;
  }
  .contact-icons {
    width: 100%;
  }
  .project-btn {
    margin: .4rem;
  }
  .HomeName {
    font-size: 2rem;
  } 
  .paragraph {
    font-size: 1.5rem;
  }
  .ClickAbout {
    font-size: .96rem;
  }
  .slider-image {
    height: 300px;
    width: 400px;
  }
  .mobile-image {
    height: 300px;
    width: 140px;
  }
  .image-container {
    height: 300px;
    width: 1200px;
    left: 80px;
  }
  .slide-container {
    height: 348px;
    width: 550px;
  }
  #slider-image-1:target ~ .image-container {
    left: 80px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -350px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -750px;
  }
  #slider-image-4:target ~ .image-container {
    left: -622px;
  }
  .project-btn {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 660px) {
  .slider-image {
    height: 250px;
    width: 350px;
  }
  .mobile-image {
    height: 250px;
    width: 140px;
  }
  .image-container {
    height: 300px;
    width: 1200px;
    left: 80px;
  }
  .slide-container {
    height: 300px;
    width: 500px;
  }
  #slider-image-1:target ~ .image-container {
    left: 80px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -275px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -630px;
  }
  #slider-image-4:target ~ .image-container {
    left: -522px;
  }
  .button-container {
    top: 0px;
    position: relative;
  }
}

@media only screen and (max-width: 600px) {
  .timeSup-wrapper, .foodscovery-wrapper, .blackjack-wrapper, .rokitshop-wrapper {
    margin: 3rem 1rem;
  }
}

@media only screen and (max-width: 584px) {
  .project-links a {
    font-size: 1.2rem;
    padding: 0 1rem;
  }
  .slider-button {
    height: 15px;
    width: 15px;
  }
  .slider-image {
    height: 280px;
    width: 350px;
  }
  .mobile-image {
    height: 280px;
    width: 130px;
  }
  .image-container {
    height: 280px;
    width: 1050px;
  }
  .slide-container {
    height: 310px;
    width: 350px
  }
  .button-container {
    top: 9px;
  }
  #slider-image-1:target ~ .image-container {
    left: 0px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -350px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -700px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -590px;
  }
  .project-btn {
    font-size: .9rem;
  }
}

@media only screen and (max-width: 530px) {
  .project-btn {
    font-size: .75rem;
  }
  .slider-button {
    height: 15px;
    width: 15px;
  }
  .slider-image {
    height: 250px;
    width: 320px;
  }
  .image-container {
    height: 250px;
    width: 960px;
  }
  .slide-container {
    height: 280px;
    width: 320px
  }
  .mobile-image {
    height: 250px;
    width: 120px;
  }

  .button-container {
    top: 8px;
  }

  #slider-image-1:target ~ .image-container {
    left: 0px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -320px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -640px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -540px;
  }
}

@media only screen and (max-width: 480px) {
  .project-btn {
    font-size: .6rem;
  }
  .slider-button {
    height: 15px;
    width: 15px;
  }
  .slider-image {
    height: 200px;
    width: 280px;
  }
  .mobile-image {
    height: 200px;
    width: 97px;
  }
  .image-container {
    height: 200px;
    width: 840px;
    left: 0px;
  }
  .slide-container {
    height: 230px;
    width: 280px
  }
  .button-container {
    top: 8px;
  }
  #slider-image-1:target ~ .image-container {
    left: 0px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -280px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -560px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -468px;
  }
}

@media only screen and (max-width: 420px) {
  .project-btn {
    font-size: .5rem;
  }
  .slider-button {
    height: 15px;
    width: 15px;
  }
  .slider-image {
    height: 180px;
    width: 240px;
  }
  .mobile-image {
    height: 180px;
    width: 85px;
  }
  .image-container {
    height: 180px;
    width: 720px;
    left: 0;
  }
  .slide-container {
    height: 240px;
    width: 240px;
  }

  .button-container {
    top: 23px;
    position: relative;
  }
  
  #slider-image-1:target ~ .image-container {
    left: 0px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -240px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -480px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -402px;
  }
  a:hover {
    opacity: 100%;
  }
  button:hover {
    opacity: 100%;
  }
  .Avatar {
    min-height: 6rem;
    min-width: 6rem;
  }
  .name {
    font-size: 1.5rem;
  }
  .role {
    font-size: 1rem;
  }
  .message form {
    padding: 1.5rem;
  }
  .contact-icons {
    width: 95%;
    margin: 1rem 0 .5rem 0;
  }
  .LightMode-wrapper {
    width: 90%;
  }
  .Experience-wrapper {
    border-radius: 0;
  }
}

@media only screen and (max-width: 400px) {
  .project-btn-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-height: 700px) {
  .Projects-wrapper {
    margin-top: 8rem;
  }
  .project-btn {
    font-size: .6rem;
  }
  .slider-button {
    height: 15px;
    width: 15px;
  }
  .slider-image {
    height: 200px;
    width: 280px;
  }
  .mobile-image {
    height: 200px;
    width: 97px;
  }
  .image-container {
    height: 200px;
    width: 840px;
    left: 0px;
  }
  .slide-container {
    height: 230px;
    width: 280px
  }
  .button-container {
    top: 8px;
  }
  #slider-image-1:target ~ .image-container {
    left: 0px;
  }
  
  #slider-image-2:target ~ .image-container {
    left: -280px;
  }
  
  #slider-image-3:target ~ .image-container {
    left: -560px;
  }
  
  #slider-image-4:target ~ .image-container {
    left: -468px;
  }
}